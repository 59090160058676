import { useAuth } from '#/auth/ui/AuthProvider'
import {
  useSubscriptionActions,
  useSubscriptionState,
} from '#/subscription/state/SubscriptionProvider'

import {
  Accessor,
  createResource,
  createSignal,
  onMount,
  createContext,
  JSX,
  useContext,
  createMemo,
} from 'solid-js'

type ProgressState = { loaded: number; total: number; percentage: number }

type HttpResource = readonly [unknown, { getProgress: () => ProgressState }]

const HttpResourceContext = createContext<{
  addResource: (resource: HttpResource) => void
  getProgress: () => ProgressState
  reset: () => void
}>({
  addResource: () => {},
  getProgress: () => ({ loaded: 0, total: 0, percentage: 0 }),
  reset: () => {},
})

const HttpResourceContextProvider = (props: { children: JSX.Element }) => {
  const [getResources, setResources] = createSignal<HttpResource[]>([])

  const addResource = (resource: HttpResource) =>
    setResources([...getResources(), resource])

  const getProgress = createMemo(() => {
    const progress = getResources()
      .map(([, r]) => r.getProgress())
      .reduce(
        (acc, curr) => ({
          loaded: acc.loaded + curr.loaded,
          total: acc.total + curr.total,
        }),
        { loaded: 0, total: 0 },
      )

    return {
      ...progress,
      percentage: progress.total ? progress.loaded / progress.total : 0,
    }
  })

  const reset = () => setResources([])

  const value = { addResource, getProgress, reset }

  return (
    <HttpResourceContext.Provider value={value}>
      {props.children}
    </HttpResourceContext.Provider>
  )
}

const useProgress = () => {
  const context = useContext(HttpResourceContext)

  return context.getProgress
}

const createHttpResource = <T extends unknown>(
  getUrl: Accessor<string | URL | undefined>,
  options?: {
    responseType?: XMLHttpRequest['responseType']
    transformResponse: (response: any) => T
    method?: 'GET' | 'POST' | 'PATCH' | 'PUT'
    needsAuth?: boolean
    needsSubscription?: boolean
  },
) => {
  const auth = useAuth()
  const subscription = useSubscriptionState()
  const { setIsDialogOpen } = useSubscriptionActions()

  const [getProgress, setProgress] = createSignal<ProgressState>({
    loaded: 0,
    total: 0,
    percentage: 0,
  })

  const { transformResponse, method = 'GET', responseType } = options ?? {}

  const [response, actions] = createResource(
    () => ({
      url: getUrl(),
      isLoggedIn: auth.isLoggedIn,
      hasActiveSubscription: options?.needsSubscription
        ? subscription.hasActiveSubscription
        : undefined,
      isSubscriptionLoading: options?.needsSubscription
        ? subscription.isSubscriptionLoading
        : undefined,
    }),
    ({ url, isLoggedIn, hasActiveSubscription, isSubscriptionLoading }) =>
      new Promise<T>((resolve, reject) => {
        if (!url) return
        if (!(isLoggedIn || options?.needsAuth === false)) return
        if (isSubscriptionLoading) return
        if (!hasActiveSubscription && options?.needsSubscription) {
          setIsDialogOpen(true)
          return
        }

        const xhr = new XMLHttpRequest()

        if (responseType) {
          xhr.responseType = responseType
        }

        xhr.addEventListener('progress', (event) => {
          setProgress({
            loaded: event.loaded,
            total: event.total,
            percentage: event.total ? event.loaded / event.total : 0,
          })
        })

        xhr.addEventListener('load', () => {
          if (transformResponse) {
            resolve(transformResponse(xhr.response))
          } else {
            resolve(xhr.response)
          }
        })
        xhr.addEventListener('error', () =>
          reject(new Error('An error occurred while sending the request.')),
        )

        xhr.addEventListener('timeout', () => {
          reject(new Error('The request timed out.'))
        })

        xhr.addEventListener('readystatechange', () => {
          if (xhr.readyState === 4 && xhr.status !== 200) {
            reject(new Error('An HTTP error occurred: ' + xhr.status))
          }
        })

        xhr.open(method, url)
        xhr.send()
      }),
    { ssrLoadFrom: 'initial' },
  )

  const context = useContext(HttpResourceContext)

  const resource = [response, { ...actions, getProgress }] as const

  onMount(() => {
    actions.refetch()
  })
  context.addResource(resource)

  return resource
}

export { createHttpResource, useProgress, HttpResourceContextProvider }
